import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../../../../../redux/store"

const mapState = (state: RootState) => ({
  pages: state.indexation.indexationAuto.queue,
  isLoading: state.indexation.fetching,
  allLoadingStates: state.indexation.pagesInIndexingState,
})

const mapDispatch = (dispatch: any) => ({})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
