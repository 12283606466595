import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useIntl } from "react-intl"
import { FilterBar } from "../components/analytics/FilterBar/FilterBar"
import { MobileNavbar } from "../components/general/MobileNavbar/MobileNavbar"
import { Navbar } from "../components/general/Navbar/Navbar"
import { Notifications } from "../components/general/Notifications/Notifications"
import { Protected } from "../components/general/Protected/Protected"
import { Seo } from "../components/general/Seo/Seo"
import { IndexationActivateSearchEngineModalModal } from "../components/indexation/IndexationActivateSearchEngineModal/IndexationActivateSearchEngineModal"
import { IndexationPageSwitcher } from "../components/indexation/IndexationPageSwitcher/IndexationPageSwitcher"
import { IndexNowCheckModal } from "../components/indexation/IndexMeNowCheckModal/IndexNowCheckModal"
import { ModalIndexAllSuccess } from "../components/indexation/ModalIndexAllSuccess/ModalIndexAllSuccess"
import { Settings } from "../components/indexation/Settings/Settings"
import { SupportUsModal } from "../components/indexation/SupportUsModal/SupportUsModal"
import { TabsChangeIndexationView } from "../components/indexation/TabsChangeIndexationView/TabsChangeIndexationView"
import { Footer } from "../components/marketing/Footer"
import { PremiumModal } from "../components/payment/UpsellConfirmationModal/UpsellConfirmationModal"
import { Container } from "../components/UI/Container"

export const IndexationRoute: React.FC<RouteComponentProps> = () => {
  const intl = useIntl()
  return (
    <Protected>
      <Seo
        title={intl.formatMessage({
          id: `seo/indexation/title`,
        })}
        description={intl.formatMessage({
          id: `seo/indexation/description`,
        })}
        lang="en"
        langUrls={[
          {
            lang: "en",
            url: "/settings",
            isDefault: true,
          },
        ]}
      />
      <Navbar />

      <Container className="relative">
        <div className="mt-4" id="pagination-anchor-scroll"></div>
        <FilterBar />
        {/* <IndexationSourceEnginesManager /> */}
        <Settings />

        <TabsChangeIndexationView />
        <IndexationPageSwitcher />
      </Container>

      <Footer />
      <MobileNavbar />
      <SupportUsModal />
      <ModalIndexAllSuccess />
      <PremiumModal />
      <IndexationActivateSearchEngineModalModal />
      <IndexNowCheckModal />
      <Notifications />
    </Protected>
  )
}
